import React from 'react';
import SectionHeading from '@components/elements/section-heading';
import TextShadow from '@components/elements/text-shadow';
import Card from '@components/elements/card';
import {reverse, sortBy} from 'lodash';
import {EventObject} from '@utils/types';
import {graphql, useStaticQuery, Link} from 'gatsby';

const EventsSection = () => {
    const {allStrapiEvent} = useStaticQuery(query);
    const {events}: {events: EventObject[]} = allStrapiEvent;

    return (
        <div className='w-full centering-col'>
            <SectionHeading>Veranstaltungen</SectionHeading>
            <div className='max-w-xl space-y-3 centering-col'>
                {reverse(sortBy(events, ['date'])).map((n) => (
                    <Link to={`/event/${n.identifier}`} className='group'>
                        <Card key={n.name}>
                            <div className='centering-col md:flex-row'>
                                <div className='relative mx-2 mb-1 text-3xl rubik md:mb-0 md:mr-4'>
                                    <TextShadow text={n.date} />
                                </div>
                                <div className='centering-col md:items-start'>
                                    <div className='mb-0.5 text-base text-gray-900 group-hover:text-noblue rubik font-weight-700 text-center md:text-left'>
                                        {n.name}
                                    </div>
                                    <div
                                        className={
                                            'text-lg text-gray-900 font-weight-500 crimson text-center md:text-left'
                                        }
                                    >
                                        {n.time}, {n.location}
                                    </div>

                                    <div
                                        className={
                                            'text-lg font-weight-300 crimson centering-row text-gray-900'
                                        }
                                    >
                                        <span className='underline'>
                                            Einladung lesen
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Link>
                ))}
            </div>
            <div className='max-w-xl mt-4 text-xl text-center text-black md:mt-6 crimson font-weight-500'>
                Zukünftige Veranstaltungen kündigen wir auf dieser Website und
                in den sozialen Medien an.{' '}
                <span className='md:hidden'>Folgt uns auf:</span>
            </div>
        </div>
    );
};

export const query = graphql`
    query {
        allStrapiEvent(filter: {onLandingPage: {eq: true}}) {
            events: nodes {
                name
                date
                time
                location
                identifier
            }
        }
    }
`;

export default EventsSection;
