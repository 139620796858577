import React from 'react';
import TextShadow from '@components/elements/text-shadow';
import {Link} from 'gatsby';

const VariantLink = (props: {icon: string; text: string; to: string}) => (
    <Link
        to={props.to}
        className={
            'relative px-3 py-1.5 rounded font-weight-700 ' +
            'bg-gray-200 hover:bg-gray-100 ' +
            'transition-colors duration-100'
        }
    >
        <div className='centering-row'>
            <div className='relative transform translate-y-0.5 mr-2 md:mr-1'>
                {props.icon}
            </div>
            <div className='relative text-gray-600 whitespace-nowrap'>
                {props.text}
            </div>
        </div>
    </Link>
);

const TextLink = (props: {text: string; to: string}) => (
    <p className='-mt-2'>
        <Link to={props.to} className='relative font-weight-700'>
            <div
                className='relative italic text-center underline text-noblue'
                style={{
                    overflowWrap: 'normal',
                    wordBreak: 'normal',
                    lineBreak: 'auto',
                }}
            >
                {props.text}
            </div>
        </Link>
    </p>
);

const AboutSection = (props: {variant: 'regular' | 'simple' | 'english'}) => (
    <div className='space-y-3 centering-col md:space-y-4'>
        <div className='relative max-w-xl p-4 mx-auto bg-white shadow md:p-6 centering-col'>
            <h2
                className={
                    'w-full absolute top-0 left-0 mx-auto centering-row ' +
                    'text-3xl text-gray-200 bg-gray-900 h-20 rubik'
                }
            >
                <div className='relative'>
                    <TextShadow variant='blue' text='Bündnis #noIAA' />
                </div>
            </h2>

            <div className='mt-20 text-xl markdown'>
                <div className='w-full mb-4 space-y-2 md:space-y-0 md:space-x-2 centering-col md:flex-row'>
                    {props.variant === 'regular' && (
                        <>
                            <VariantLink
                                text='Leichte Sprache'
                                icon='📖'
                                to='/leichte-sprache'
                            />
                            <VariantLink
                                text='English Version'
                                icon='🇬🇧'
                                to='/en'
                            />
                        </>
                    )}
                    {props.variant === 'simple' && (
                        <VariantLink
                            text='Allgemeine Version'
                            icon='📖'
                            to='/'
                        />
                    )}
                    {props.variant === 'english' && (
                        <VariantLink text='Deutsche Version' icon='🇩🇪' to='/' />
                    )}
                </div>

                {props.variant === 'regular' && (
                    <p>
                        Das Bündnis{' '}
                        <strong className='text-noblue'>#noIAA</strong> wurde
                        Anfang 2021 gegründet und versteht sich als Teil der
                        globalen Bewegung für Klima&shy;gerechtig&shy;keit. Der{' '}
                        <strong>
                            zivil&shy;gesell&shy;schaft&shy;liche und
                            ungehorsame Protest
                        </strong>{' '}
                        hat die Inter&shy;nationale Automobil&shy;ausstellung
                        (IAA) aus Frankfurt vertrieben. Daran knüpfen wir als
                        Münchner Bündnis an.
                    </p>
                )}
                {props.variant === 'simple' && (
                    <p>
                        Seit Anfang 2021 gibt es eine neue Gruppe. Sie heißt{' '}
                        <strong className='text-noblue'>#noIAA</strong> München.
                        Die Gruppe gehört zur welt&shy;weiten Bewe&shy;gung für
                        Klima&shy;gerechtig&shy;keit.
                        Klima&shy;gerechtig&shy;keit ist Klimaschutz, der sozial
                        gerecht ist für die ganze Welt. In Frankfurt gab es 2019
                        die Inter&shy;nationale Auto&shy;mobil&shy;ausstellung
                        (IAA). Auto&shy;kon&shy;zer&shy;ne wollen Autos
                        verkaufen. Deshalb zeigen sie dort Autos. Viele Menschen
                        in Frankfurt haben sich dagegen gewehrt. 2021 soll die
                        IAA nach München kommen. Dagegen möchte sich die Gruppe
                        #noIAA wehren.
                    </p>
                )}
                {props.variant === 'english' && (
                    <p>
                        The alliance{' '}
                        <strong className='text-noblue'>#noIAA</strong> was
                        founded in Munich in early 2021 and sees itself as part
                        of the global move&shy;ment for climate justice. The{' '}
                        <strong>civic and disobedient protests</strong> drove
                        the Inter&shy;national Motor Show (German
                        abbre&shy;viation: IAA) away from Frank&shy;furt. As
                        Munich’s alliance, we’re con&shy;tinuing where they left
                        off.
                    </p>
                )}

                <img
                    src='/logo-text.svg'
                    className='mb-2 -mt-4 md:-mt-2 md:mb-4'
                    alt='Bündnis #noIAA Logo'
                    width='2000'
                    height='800'
                />
                {props.variant === 'regular' && (
                    <>
                        <p>
                            Die Auto&shy;konzerne versuchen nun in München eine
                            erneute Nieder&shy;lage zu vermeiden und verpassen
                            der IAA einen <strong>grünen Anstrich</strong>. Doch
                            allen Green&shy;washing-Bemühungen zum Trotz – die
                            IAA bleibt weiterhin ein{' '}
                            <strong>Symbol für Profit&shy;maximierung</strong>{' '}
                            auf Kosten von Mensch, Umwelt und Klima.
                        </p>
                        <TextLink
                            to='/blog/iaa-2019-in-frankfurt'
                            text='Mehr zu den Er&shy;eig&shy;nissen in Frank&shy;furt'
                        />
                    </>
                )}
                {props.variant === 'simple' && (
                    <>
                        <p>
                            Die Autokonzerne wollen in München Erfolg und keine
                            Proteste. Deswegen wollen sie so tun, als ob Autos
                            umweltfreundlich wären. Das nennt man auch{' '}
                            <strong>Green&shy;washing</strong>. Aber in
                            Wirklichkeit bleibt die IAA ein Zeichen für
                            Profit&shy;maxi&shy;mierung.
                            Profit&shy;maxi&shy;mierung ist, wenn reiche
                            Menschen sogar noch mehr Geld bekommen. Die IAA und
                            die Auto&shy;industrie schaden Menschen, Umwelt und
                            Klima. Mit diesem Schaden verdienen sie Geld.
                        </p>
                        <TextLink
                            to='/blog/iaa-2019-in-frankfurt'
                            text='Mehr zu den Er&shy;eig&shy;nissen in Frank&shy;furt'
                        />
                    </>
                )}
                {props.variant === 'english' && (
                    <p>
                        Car companies are trying to avoid another defeat in
                        Munich by making the IAA appear ecological. But in spite
                        of all their attempts at{' '}
                        <strong>green&shy;washing</strong>, the IAA remains a{' '}
                        <strong>symbol of profit maxi&shy;mi&shy;sation</strong>{' '}
                        at the costs of humans, the en&shy;viron&shy;ment and
                        the climate.
                    </p>
                )}
            </div>
        </div>
        <div className='relative max-w-4xl mx-auto bg-white shadow centering-col'>
            <img
                src='/anmalen.jpeg'
                className='w-full h-auto'
                alt='Ein Auto wird grün angemalt'
                width='1280'
                height='655'
            />
            <div
                className={
                    'w-full px-4 sm:px-6 py-3 text-center ' +
                    'text-xl font-weight-500 crimson ' +
                    'text-gray-200 bg-gray-900'
                }
            >
                Susanne Klatten, Hildegard Müller{' '}
                {props.variant === 'english' ? 'and' : 'und'} Andreas Scheuer
            </div>
        </div>
        <div className='max-w-xl p-4 mx-auto bg-white shadow md:p-6 centering-col'>
            <div className='text-xl markdown'>
                {props.variant === 'regular' && (
                    <>
                        <p>
                            Der Ausbau des Autoverkehrs sowie die
                            Aufrecht&shy;erhaltung der Auto&shy;produktion und
                            deren staatlicher Förderung be&shy;feuern die{' '}
                            <strong>sozialen und ökologischen Krisen</strong>{' '}
                            weiter. Die konstant hohen CO2-Emissionen des
                            Verkehrssektors ver&shy;schärfen die Klimakrise.
                            Deren Folgen treffen weltweit besonders ärmere
                            Menschen, vor allem im globalen Süden. Gleichzeitig
                            führt der Autoverkehr lokal zu Flächen&shy;verbrauch
                            sowie zu Gesund&shy;heits&shy;schäden durch Lärm,
                            Stress und Feinstaub.
                        </p>
                        <p>
                            Die Nutzung des öffentlichen Raums
                            (Marien&shy;platz, Odeons&shy;platz,
                            Königs&shy;platz u.v.m.) für die IAA wurde{' '}
                            <strong>
                                ohne gesell&shy;schaft&shy;liche und
                                demo&shy;kra&shy;tische Kon&shy;trolle
                            </strong>{' '}
                            in nicht&shy;öffentlichen Sitzungen des Stadtrats
                            beschlossen. Auch die Grünen haben der Bewerbung um
                            die IAA zugestimmt und stellen damit den
                            Wirt&shy;schafts&shy;standort München über den
                            Umweltschutz.
                        </p>
                        <TextLink
                            to='/blog/iaa-ohne-demokratie'
                            text='Mehr zur Ent&shy;schei&shy;dung für die IAA in München'
                        />
                    </>
                )}
                {props.variant === 'simple' && (
                    <>
                        <p>
                            Die Klimakrise und die sozialen Krisen werden
                            schlimmer, wenn wir gleich viele oder mehr Autos
                            nutzen. Der Verkehr stößt seit Jahr&shy;zehnten sehr
                            viel CO2 aus. Dadurch wird die Klima&shy;krise
                            schlimmer. Das ist vor allem für arme Menschen und
                            Menschen in ärmeren Ländern sehr schlimm. In
                            Deutschland ver&shy;braucht der Auto&shy;verkehr
                            sehr viel Platz.{' '}
                            <strong>
                                Autos machen Lärm, Stress und schäd&shy;liche
                                Gase
                            </strong>
                            . Das macht Menschen krank.
                        </p>
                        <p>
                            Die IAA wird große öffent&shy;liche Flächen nutzen.
                            Dann stehen Autos auf dem Marien&shy;platz, dem
                            Odeons&shy;platz, dem Königs&shy;platz und vielen
                            mehr. Das hat der Stadtrat so entschieden. Bei der
                            Ent&shy;schei&shy;dung konnten Menschen nicht
                            zusehen und deshalb hatten sie keinen Einfluss
                            darauf. Auch die Partei die Grünen war für die IAA.
                            Die Wirt&shy;schaft in München ist ihnen also
                            wichtiger als der Umwelt&shy;schutz.
                        </p>
                        <TextLink
                            to='/blog/iaa-ohne-demokratie'
                            text='Mehr zur Ent&shy;schei&shy;dung für die IAA in München'
                        />
                    </>
                )}
                {props.variant === 'english' && (
                    <>
                        <p>
                            The expansion of car traffic as well as the unabated
                            car production and state subsidies by the state
                            continue to fuel{' '}
                            <strong>social and eco&shy;logical crises</strong>.
                            Un&shy;miti&shy;gated carbon emissions of the
                            transport sector aggravate the climate crisis. Its
                            con&shy;sequen&shy;ces dis&shy;propor&shy;tionately
                            affect poorer people and people of the global south.
                            Further&shy;more, car traffic requires entensive
                            land usage and is detri&shy;mental to health through
                            noise, stress and fine par&shy;ticulate matter.
                        </p>
                        <p>
                            The use of public spaces (Marien&shy;platz,
                            Odeons&shy;platz, Köngis&shy;platz and others) for
                            the IAA was decided in non-public council meetings{' '}
                            <strong>
                                lacking civic and demo&shy;cratic control
                            </strong>
                            . The Green Party voted for the promotion of the IAA
                            as well, priori&shy;tizing local corporate
                            in&shy;terests over environ&shy;mental
                            protec&shy;tion.
                        </p>
                        <p>
                            We advocate for a{' '}
                            <strong>
                                radical tran&shy;sition of the transport system
                            </strong>{' '}
                            – not some day, but now. Many people remain forced
                            to use cars at high expense in many places.
                            Alter&shy;native fuels alone are not the solution.
                            Our aims are mostly car-free cities and very limited
                            car traffic in rural areas. We need more space for
                            pedes&shy;trians and cyclists as well as a highly{' '}
                            <strong>
                                extended, ticket-free local public transport
                                system
                            </strong>{' '}
                            with higher wages and better working conditions for
                            employees. Further construc&shy;tion of highways and
                            streets must be stopped.
                        </p>
                    </>
                )}
            </div>
        </div>
        {props.variant !== 'english' && (
            <div className='max-w-xl p-4 mx-auto bg-white shadow md:p-6 centering-col'>
                <div className='text-xl markdown'>
                    {props.variant === 'regular' && (
                        <p>
                            Wir setzen uns für eine{' '}
                            <strong>radikale Verkehrs&shy;wende</strong> ein –
                            nicht irgend&shy;wann, sondern jetzt. Noch sind
                            vielerorts Menschen zur kosten&shy;inten&shy;siven
                            Auto&shy;nutzung gezwungen. Alternative
                            An&shy;triebe allein sind keine Lösung. Unser Ziel
                            sind weit&shy;gehend autofreie Städte und autoarme
                            ländliche Regionen. Wir brauchen mehr Platz für Fuß-
                            und Radverkehr sowie einen massiv{' '}
                            <strong>
                                ausgebauten, ticket&shy;freien Nahverkehr
                            </strong>{' '}
                            mit höheren Löhnen und besseren
                            Arbeits&shy;bedingungen für die Beschäf&shy;tigten.
                            Der Bau von Auto&shy;bahnen, Schnell- und
                            Bundes&shy;straßen muss sofort gestoppt werden.
                        </p>
                    )}
                    {props.variant === 'simple' && (
                        <p>
                            Die Gruppe #noIAA will eine{' '}
                            <strong>radikale Verkehrs&shy;wende</strong>. Das
                            ist eine starke Ver&shy;änderung des Verkehrs. Wir
                            wollen das jetzt. An vielen Orten müssen Menschen
                            Autos nutzen. Es gibt keine Alter&shy;nativen. Das
                            ist sehr teuer für sie. Alter&shy;native Antriebe
                            wie Elektro&shy;mobilität reichen für diese
                            Ver&shy;änderung nicht. Autos sollen in Städten
                            selten sein. Es soll weniger Autos auf dem Land
                            geben. Wir brauchen Platz für{' '}
                            <strong>
                                Fahrrad&shy;fahren und Zufuß&shy;gehen
                            </strong>
                            . Wir brauchen mehr{' '}
                            <strong>
                                Busse, Tram&shy;bahnen, Ubahnen und Züge
                            </strong>
                            . Diese sollen nichts kosten. Die
                            An&shy;ge&shy;stellten wie Bus&shy;fahrende sollen
                            besser bezahlt werden. Ihre
                            Arbeits&shy;bedin&shy;gun&shy;gen sollen besser
                            sein. Das heißt zum Beispiel: Sie sollen sich in der
                            Arbeit wohl fühlen und die Arbeit sicher behalten
                            können. Es darf keine neuen Auto&shy;bahnen,
                            Schnell&shy;straßen und Bun&shy;des&shy;straßen
                            geben. Und sie sollen nicht verlän&shy;gert werden.
                        </p>
                    )}
                </div>
            </div>
        )}
        <div className='relative max-w-4xl mx-auto bg-white shadow centering-col'>
            <img
                src='/nicht_verarschen.jpeg'
                className='w-full h-auto'
                alt='Wir lassen uns nicht verarschen!'
                width='1280'
                height='853'
            />
        </div>
        <div className='max-w-xl p-4 mx-auto bg-white shadow md:p-6 centering-col'>
            <div className='text-xl markdown'>
                {props.variant === 'regular' && (
                    <>
                        <p>
                            Angesichts der Klimakrise muss die Anzahl der Autos
                            drastisch reduziert werden und der{' '}
                            <strong>sozial-öko&shy;logische Umbau</strong> der
                            Auto&shy;industrie beginnen. Dabei müssen alle
                            Möglich&shy;keiten zur Konversion ausgeschöpft und
                            die Arbeitszeit bei vollem Lohn&shy;ausgleich
                            verkürzt werden. Die Schaffung gleich&shy;wertiger
                            Arbeits&shy;plätze in Bereichen, die dem
                            öko&shy;logischen Umbau dienen, muss massiv
                            gefördert werden. Dieser Prozess muss mit den
                            Beschäftigten gemeinsam organisiert werden und die
                            soziale Absicherung aller garantieren.
                        </p>
                        <p>
                            Für die Mobilitäts&shy;wende müssen wir die
                            Auto&shy;konzerne entmachten und{' '}
                            <strong>
                                Wirt&shy;schafts&shy;ent&shy;scheidungen
                                demo&shy;kra&shy;ti&shy;sie&shy;ren
                            </strong>
                            .
                        </p>
                    </>
                )}
                {props.variant === 'simple' && (
                    <>
                        <p>
                            Wegen der Klimakrise müssen wir viel weniger Autos
                            haben. Die Auto&shy;industrie muss verändert werden.
                            Sie soll{' '}
                            <strong>sozial und öko&shy;logisch werden</strong>.
                            Wir müssen alles in der Auto&shy;industrie
                            verändern, das verändert werden kann. Die
                            An&shy;ge&shy;stellten sollen weniger arbeiten
                            müssen. Sie sollen aber auch gleich viel verdienen.
                            Wir wollen neue Arbeits&shy;plätze in
                            umwelt&shy;freund&shy;lichen Bereichen.
                            An&shy;ge&shy;stellte aus der Autoindustrie sollen
                            in diesen neuen Arbeits&shy;plätzen arbeiten können.
                            Diese Ver&shy;än&shy;derung sollen sie selbst
                            mit&shy;bestimmen können. Sie sollen sichere
                            Arbeits&shy;plätze haben. Wenn sie eine Zeit lang
                            keine Arbeit haben, dann müssen sie genug Geld für
                            sich und ihre Familie bekommen.
                        </p>
                        <p>
                            Für die Verkehrs&shy;wende müssen wir den
                            Auto&shy;firmen die Macht wegnehmen. Die{' '}
                            <strong>
                                Menschen sollen die Ent&shy;schei&shy;dungen
                                über die Wirt&shy;schaft treffen
                            </strong>
                            .{' '}
                        </p>
                    </>
                )}
                {props.variant === 'english' && (
                    <>
                        <p>
                            In the face of the climate crisis, the number of
                            cars needs to be reduced drasti&shy;cally and the{' '}
                            <strong>socio-ecological transition</strong> of the
                            car industry needs to be started. In doing so, all
                            possi&shy;bili&shy;ties for a conversion need to be
                            seized and working hours need to be reduced with
                            full wage compen&shy;sation. The
                            estab&shy;lish&shy;ment of equi&shy;valent
                            employ&shy;ment in the sector for ecological
                            transition has to be intensely promoted. This
                            process needs to be organised in coopera&shy;tion
                            with employees and social security needs to be
                            guaranteed.
                        </p>
                        <p>
                            For the transition of the transport system, we need
                            to dis&shy;empower car compa&shy;nies and{' '}
                            <strong>
                                demo&shy;cratise economic decision making
                            </strong>
                            .
                        </p>
                        <p className='pt-2 text-xl italic font-weight-500 crimson markdown'>
                            The rest of this website is only available in
                            german. For any further questions please contact{' '}
                            <a
                                className='underline'
                                href='mailto:info@noiaa.de'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                info@noiaa.de
                            </a>
                            .
                        </p>
                    </>
                )}
            </div>
        </div>
    </div>
);

export default AboutSection;
