import React from 'react';
import SectionHeading from '@components/elements/section-heading';
import {sortBy} from 'lodash';
import {SupporterObject} from '@utils/types';
import {graphql, useStaticQuery} from 'gatsby';

const SupportersSection = () => {
    const {allStrapiSupporter} = useStaticQuery(query);
    const {supporters}: {supporters: SupporterObject[]} = allStrapiSupporter;

    return (
        <div className='w-full centering-col'>
            <SectionHeading>Unterstützer*innen</SectionHeading>
            <div className='grid max-w-4xl grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-2'>
                {sortBy(supporters, (s) => s.name.toUpperCase()).map((s) => (
                    <div
                        key={s.name}
                        className='w-full h-full p-3 bg-white shadow flex-col-left'
                    >
                        <div className='mb-1 text-base leading-tight text-gray-900 rubik font-weight-700'>
                            {s.name}
                        </div>
                        {s.url && s.url.startsWith('http') && (
                            <a
                                className={
                                    'text-lg text-gray-900 font-weight-300 crimson leading-tight underline'
                                }
                                href={s.url}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {s.url}
                            </a>
                        )}
                        {(!s.url || !s.url.startsWith('http')) && (
                            <div
                                className={
                                    'text-lg text-gray-900 font-weight-300 crimson leading-tight'
                                }
                            >
                                -
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const query = graphql`
    query {
        allStrapiSupporter {
            supporters: nodes {
                name
                url
            }
        }
    }
`;

export default SupportersSection;
