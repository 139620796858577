import React from 'react';
import Card from '@components/elements/card';
import {sortBy} from 'lodash';
import {graphql, useStaticQuery, Link} from 'gatsby';
import {BlogEntryObject} from '@utils/types';

const BlogSection = () => {
    const {allStrapiBlogEntry} = useStaticQuery(query);
    const {blogEntries}: {blogEntries: BlogEntryObject[]} = allStrapiBlogEntry;

    // not used right now:
    // und unsere Vor&shy;schläge für eine echte Verkehrs&shy;wende 🚃 🚲 🏔

    return (
        <div
            className='relative block w-full pt-24 -mt-24 space-y-3 centering-col'
            id='blog'
        >
            <div className='max-w-xl mt-8 mb-4 text-xl text-center crimson font-weight-500'>
                In den folgenden Artikeln findet ihr alle Belege 📑 zu den
                genannten Thesen sowie auch alle zukünftigen Artikel.
            </div>
            <div
                style={{width: '36rem'}}
                className='max-w-full space-y-3 centering-col'
            >
                {sortBy(blogEntries, ['position']).map((b) => (
                    <Link
                        className='w-full cursor-pointer group'
                        to={`/blog/${b.identifier}`}
                        key={b.identifier}
                    >
                        <Card key={b.headline} variant='yellow'>
                            <div className='w-full mb-1 text-xl text-center text-gray-900 transition-colors duration-150 crimson font-weight-700 group-hover:text-noblue flex-row-top'>
                                {b.headline}
                            </div>
                        </Card>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export const query = graphql`
    query {
        allStrapiBlogEntry(filter: {onLandingPage: {eq: true}}) {
            blogEntries: nodes {
                author
                headline
                identifier
            }
        }
    }
`;

export default BlogSection;
