import React from 'react';

function Badge(props: {
    children: React.ReactNode;
    variant: 'yellow' | 'orange' | 'rose';
    url: string;
}) {
    let textColor = 'group-hover:text-noblue ';
    let bgDark = 'group-hover:bg-noyellow ';
    let bgLight = 'group-hover:bg-white ';
    const transitionClasses = 'transition-colors duration-150';

    switch (props.variant) {
        case 'yellow':
            textColor += 'text-yellow-700';
            bgDark += 'bg-yellow-700';
            bgLight += 'bg-yellow-100';
            break;
        case 'orange':
            textColor += 'text-orange-700';
            bgDark += 'bg-orange-700';
            bgLight += 'bg-orange-100';
            break;
        case 'rose':
            textColor += 'text-rose-700';
            bgDark += 'bg-rose-700';
            bgLight += 'bg-rose-100';
            break;
    }
    return (
        <a
            className='relative group'
            href={props.url}
            target='_blank'
            rel='noopener noreferrer'
        >
            <div
                className={
                    'relative z-10 block w-full h-full px-3 py-1.5 shadow flex-col-left ' +
                    'rubik text-lg font-weight-700 ' +
                    `${textColor} ${bgLight} ${transitionClasses}`
                }
            >
                {props.children}
            </div>
            <div
                className={
                    'absolute top-0 left-0 z-0 block w-full h-full transform translate-x-1 translate-y-1 shadow ' +
                    `${bgDark} ${transitionClasses}`
                }
            />
        </a>
    );
}

export default Badge;
