import React from 'react';

function SectionHeading(props: {children: React.ReactNode}) {
    return (
        <h2 className='relative mx-auto mb-4 text-3xl text-center rubik'>
            {props.children}
        </h2>
    );
}

export default SectionHeading;
