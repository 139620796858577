import React from 'react';

function Section(props: {
    id: string;
    children: React.ReactNode;
    dark?: boolean;
}) {
    return (
        <section
            className={
                'relative z-10 py-12 centering-col ' +
                (props.dark ? 'bg-gray-300 -ml-2 pl-4 pr-2' : '')
            }
            style={{
                width: props.dark ? 'calc(100% + 1.5rem)' : '100%',
            }}
            id={props.id + '-component'}
        >
            <div
                className='absolute top-0 left-0 transform -translate-y-16'
                id={props.id}
            />
            <div className='w-full centering-col'>{props.children}</div>
        </section>
    );
}

export default Section;
