import React, {useState} from 'react';
import {Link} from 'gatsby';
import useEvent from '@utils/use-event';

function Header(props: {links: {title: string; id: string}[]}) {
    const [sectionIndex, setSectionIndex] = useState(0);

    function updateSectionIndex() {
        const scroll = window.pageYOffset;
        let newIndex = 0;
        for (let i = 1; i < props.links.length; i++) {
            const offset = document.getElementById(
                props.links[i].id + '-component',
            ).offsetTop;
            if (offset - scroll < window.innerHeight * (1 / 3)) {
                newIndex++;
            } else {
                break;
            }
        }

        setSectionIndex(newIndex);
    }

    useEvent('scroll', updateSectionIndex);

    return (
        <header
            className={
                'bg-white shadow fixed top-0 left-0 right-0 h-16 z-50 ' +
                'flex flex-row items-center justify-start px-4 md:px-8 ' +
                'overflow-x-scroll hide-scrollbar'
            }
        >
            <Link className='flex-shrink-0 hidden h-12 md:block md:mr-6' to='/'>
                <img
                    src='/logo.svg'
                    alt='Bündnis #noIAA Logo'
                    width='400'
                    height='140'
                    className='w-auto h-12'
                />
            </Link>
            {props.links.map((l, i: number) => (
                <Link
                    to={`#${l.id}`}
                    key={l.title}
                    className={
                        'text-xl rubik mt-0.5 ' +
                        'relative whitespace-nowrap mr-6 text-gray-900'
                    }
                >
                    <div
                        className={
                            'relative z-10 transition-colors duration-100 ' +
                            (sectionIndex === i ? 'opacity-100' : 'opacity-75')
                        }
                    >
                        {l.title}
                    </div>
                    <div
                        className={
                            'absolute top-0 left-0 z-0 no-selection ' +
                            'transform translate-x-0.75 translate-y-0.75 ' +
                            'transition-colors duration-100 ' +
                            (sectionIndex === i
                                ? 'text-noyellow '
                                : 'text-transparent ')
                        }
                    >
                        {l.title}
                    </div>
                </Link>
            ))}
            <div className='relative flex-shrink-0 block w-1 h-5' />
        </header>
    );
}

export default Header;
