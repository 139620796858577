import React, {useState} from 'react';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import Badge from '@components/elements/badge';
import Card from '@components/elements/card';

const NewsSection = () => {
    const [twitterMounted, setTwitterMounted] = useState(false);

    return (
        <div className='w-full mt-0 centering-col'>
            <div className='mt-1 mb-10 space-x-3 space-y-3 md:mt-2 md:mb-8 centering-col md:flex-row'>
                <div className='hidden text-xl crimson font-weight-500 md:block'>
                    Folgt uns auf:
                </div>
                <Badge variant='yellow' url='https://twitter.com/no_iaa'>
                    Twitter
                </Badge>
                <Badge variant='orange' url='https://www.facebook.com/noiaamuc'>
                    Facebook
                </Badge>
                <Badge variant='rose' url='https://www.instagram.com/noiaa.muc'>
                    Instagram
                </Badge>
            </div>

            <div
                className={
                    'max-w-full ' +
                    (!twitterMounted
                        ? 'cursor-pointer text-gray-600 hover:text-gray-900 hover:shadow-lg transition-all duration-100'
                        : '')
                }
                style={{width: '36rem'}}
                onClick={() => setTwitterMounted(true)}
            >
                <Card>
                    {twitterMounted && (
                        <div className='w-full px-3 sm:px-0 twitter'>
                            <TwitterTimelineEmbed
                                sourceType='profile'
                                screenName='no_iaa'
                                options={{height: 800}}
                                placeholder={
                                    <div className='relative w-auto px-3 py-1 m-4 mx-auto text-lg text-center crimson font-weight-500'>
                                        Lade Inhalte ...
                                    </div>
                                }
                            />
                        </div>
                    )}
                    {!twitterMounted && (
                        <div className='relative w-auto px-3 py-1 m-4 mx-auto text-lg text-center crimson font-weight-500'>
                            Zeige den <strong>@no_iaa</strong> Twitter Feed
                            <br />
                            <i>Externer Inhalt</i>
                        </div>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default NewsSection;
