import React from 'react';
import SectionHeading from '@components/elements/section-heading';
import Card from '@components/elements/card';
import {sortBy, reverse} from 'lodash';
import {PressArticleObject} from '@utils/types';
import {graphql, useStaticQuery} from 'gatsby';

const PressSection = () => {
    const {allStrapiPressArticle} = useStaticQuery(query);
    const {articles}: {articles: PressArticleObject[]} = allStrapiPressArticle;

    return (
        <div className='w-full centering-col'>
            <SectionHeading>In der Presse</SectionHeading>
            <div
                style={{width: '36rem'}}
                className='max-w-full space-y-3 centering-col'
            >
                {reverse(sortBy(articles, ['date'])).map((s) => (
                    <Card key={s.headline} variant='yellow'>
                        <div className='text-lg text-gray-900 crimson font-weight-500'>
                            {s.date} - {s.source}
                        </div>

                        <div className='my-1 text-xl leading-tight text-gray-900 rubik font-weight-700'>
                            {s.headline}
                        </div>
                        <div
                            className={
                                'text-lg text-gray-900 font-weight-500 crimson leading-tight mb-2'
                            }
                        >
                            {s.subtitle}
                        </div>
                        <a
                            className={
                                'text-lg text-gray-900 font-weight-300 crimson leading-tight underline break-all'
                            }
                            href={s.url}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {s.url}
                        </a>
                    </Card>
                ))}
            </div>
            <div className='text-xl mt-9 crimson font-weight-500'>
                📮 Presseanfragen an{' '}
                <a
                    href='mailto:info@noiaa.de'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='font-weight-700'
                >
                    info@noiaa.de
                </a>
            </div>
        </div>
    );
};

export const query = graphql`
    query {
        allStrapiPressArticle {
            articles: nodes {
                source
                headline
                subtitle
                date
                url
            }
        }
    }
`;

export default PressSection;
